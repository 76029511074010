import type {GeoLocation} from "~/types/geodata";

export const useLocationDataManager = () => {
    const nuxtApp = useNuxtApp();


    const fetchLocationSuggestionsByQuery = async (query: string) => {
        const { suggestions, error } = await nuxtApp.$searchLocationsByQuery(query);
        if (error != null) {
            useAlertSetter().setErrorAlert(error);
            return [];
        } else {
            return suggestions as GeoLocation[]
        }

    }

    return { fetchLocationSuggestionsByQuery }

}